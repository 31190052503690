<template>
    <div class=""></div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "NavbarSite",
    data() {
        return {};
    },
    methods: {
        ...mapActions([]),
    },
    created() {},
    computed: {},
    mounted() {},
};
</script>
