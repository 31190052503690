<template>
    <div>
        <div class="w-100 mb-10 text-primary" v-if="header">{{ header }}</div>

        <textarea
            :type="type"
            :value="value"
            :placeholder="placeholder"
            class=" searchbox-textarea"
            autocomplete
            @input="$emit('input', $event.target.value)"
        />
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: String,
            default: "text",
        },
        header: {
            type: String,
        },
        value: {},
        placeholder: {
            type: String,
            default: "",
        },
    },
    components: {},
    methods: {},
};
</script>
<style lang="scss">
@import "../assets/scss/_variables.scss";
.searchbox-textarea {
    width: calc(100% - 30px);
    height: 200px;
    border-radius: 10px;
    border: 1px solid $primary;
    padding: 15px;
    font-family: "Ubuntu";
    font-size: 16px;
    color: #1a1a1a;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none;
}
</style>
