<template>
  <div class="selectbox">
    <div class="selectbox--scrollbox scrollbar" :class="classes">
      <p
        class="ph-10 p--selectitem"
        v-for="option in options"
        :key="option.id"
        :class="option.id == selectedId ? 'text__primary' : ''"
        @click="makeClickEvent(option)"
      >
        {{ option.name }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SelectBox",
  props: ["classes", "area", "options", "selectedId"],
  data() {
    return {};
  },
  methods: {
    ...mapActions([]),
    makeClickEvent(option) {
      option.area = this.area;
      this.$emit("click", option);
    },
  },
  created() {},
  computed: {
    ...mapGetters([]),
  },
  mounted() {},
};
</script>
