import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import password from "./modules/password";
import options from "./modules/options";
import companies from "./modules/companies";
import appointments from "./modules/appointments";
import chat from "./modules/chat";
import TextareaAutosize from "vue-textarea-autosize";

Vue.use(TextareaAutosize);
Vue.use(Vuex);
import VueYoutube from "vue-youtube";
import vueVimeoPlayer from 'vue-vimeo-player'

Vue.use(VueYoutube);
Vue.use(vueVimeoPlayer);
import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_API_KEY, //reanmo
        libraries: "places",
    },
});
export default new Vuex.Store({
    modules: {
        auth,
        options,
        companies,
        appointments,
        chat,
        password,
    },
});
