var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select",class:_vm.selectActive && _vm.classes
			? _vm.classes + ' select--active'
			: _vm.classes
			? _vm.classes
			: ''},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.removeActive),expression:"removeActive"}],staticClass:"select__box"},[_c('div',{staticClass:"select__box--input",on:{"click":function($event){$event.stopPropagation();return _vm.setActive($event)}}},[(_vm.readonly)?_c('input',{staticClass:"select__input",attrs:{"type":"text","placeholder":_vm.placeholder,"readonly":""},domProps:{"value":_vm.noName ? _vm.selectedItem : _vm.selectedItem.name}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedItem.name),expression:"selectedItem.name"}],staticClass:"select__input",attrs:{"type":"text","placeholder":_vm.placeholder},domProps:{"value":(_vm.selectedItem.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.selectedItem, "name", $event.target.value)}}}),_c('SelectArrowSvg')],1),_c('div',{staticClass:"select__options--box scrollbar",attrs:{"id":"selectOptionsBox"}},_vm._l((_vm.filteredOptions),function(option,index){return _c('p',{key:index,staticClass:"ph-10 p--selectitem",class:_vm.noName
						? option == _vm.selectedItem
							? 'text__primary'
							: ''
						: _vm.selectedID == option.id
						? 'text__primary'
						: '',on:{"click":function($event){$event.stopPropagation();return _vm.makeChangeEvent(option)}}},[_vm._v(" "+_vm._s(_vm.noName ? option ? option.trim() : "" : option.name ? option.name.trim() : "")+" ")])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }