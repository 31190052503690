<template>
  <div class="modal " v-if="showModal">
    <div class="modal__box " v-click-outside="closeModal">
      <div class="modal__box--header row__spacebetween">
        <h2 class="text__black m-0">Termingespräch</h2>
        <div @click="closeModal">
          <CloseSvg />
        </div>
      </div>
      <div class="modal__body scrollbar">
        <p class="text__primary">Art des Gesprächs</p>
        <div class="row__start ">
          <CheckBox
            v-for="chatType in companyChatTypes"
            :key="chatType.id"
            classes="mr-25 mt-10"
            :active="chatType.id == selectedChatTypeId ? true : false"
            :text="chatType.name"
            @click="changeSelectedChatType"
            :id="chatType.id"
          />
        </div>
        <p class="error__message mt-20 mb-20" v-if="errorMessage">
          {{ errorMessage }}
        </p>
        <p class="text__primary mt-20 ">Vereinbare jetzt einen Termin</p>
        <GeneralSelect
          :options="companyAppointments"
          placeholder="Tag auswählen"
          :selectedItem="selectedDay"
          classes="mt-4p"
          @change="setDay"
        />
        <p class="text__primary mt-15 ">Freie Termine</p>
        <GeneralSelect
          :options="allTimes"
          placeholder="Uhrzeit auswählen"
          :selectedItem="selectedTime"
          classes="mt-4p"
          @change="setTime"
        />
        <h3 class="mb-20 mt-30" v-if="showContacts.length > 0">
          Gesprächspartner*in auswählen
        </h3>

        <UserCheckBox
          v-for="user in showContacts"
          :key="user.id"
          classes="mb-30"
          :active="user.id == selectedUserId ? true : false"
          :user="user"
          @click="changeSelectedUser"
        />
        <div class="modal__footer--responsive mt-20 row__spacebetween">
          <div class="button button--reverse mr-15" @click="closeModal">
            <p class="text__primary text__semibold">Abbrechen</p>
          </div>
          <div
            class="button"
            :class="
              selectedUserId == 0 || selectedChatTypeId == 0
                ? 'button--grey'
                : ''
            "
            @click="prepareAppointment"
          >
            <p class="text__white">Termin buchen</p>
          </div>
        </div>
      </div>
      <div class="modal__footer row_end">
        <div class="button button--reverse mr-45" @click="closeModal">
          <p class="text__primary text__semibold">Abbrechen</p>
        </div>
        <div
          class="button"
          :class="
            selectedUserId == 0 || selectedChatTypeId == 0 ? 'button--grey' : ''
          "
          @click="prepareAppointment"
        >
          <p class="text__white">Termin buchen</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ClickOutside from "vue-click-outside";
export default {
  name: "AppointmentModal",
  props: ["company_id", "showModal"],
  data() {
    return {
      selectedUserId: 0,
      selectedChatTypeId: 0,
      selectedDay: { id: null, name: "" },
      selectedTime: { id: null, name: "" },
      allTimes: [],
      showContacts: [],
      isLoading: false,
      errorMessage: "",
    };
  },
  methods: {
    ...mapActions(["bookAppointment"]),
    async prepareAppointment() {
      if (
        this.selectedUserId == 0 ||
        this.selectedChatTypeId == 0 ||
        this.isLoading
      ) {
        return;
      }
      this.isLoading = true;
      let target = event.target;
      if (target.classList.contains("button")) {
        target = target.childNodes[0];
      }
      target.innerHTML = "<div class='loading'></div>";
      let data = {
        contactId: this.selectedUserId,
        chatTypeId: this.selectedChatTypeId,
        day: this.selectedDay.name,
        time: this.selectedTime.name,
        companyId: this.company_id,
      };
      const succeeded = await this.bookAppointment(data);
      if (succeeded) {
        setTimeout(() => {
          this.closeModal();
        }, 500);
      } else {
        this.errorMessage =
          "Dieser Termin wurde kurz vor ihrer Anfrage vergeben.";
        this.selectedTime = { id: null, name: "" };
        this.selectedDay = { id: null, name: "" };
        this.allTimes = [];
        this.selectedUserId = 0;
        this.showContacts = [];
      }
      target.innerHTML = "Termin buchen";
      this.isLoading = false;
    },
    setDay(option) {
      this.selectedTime = { id: null, name: "" };
      this.selectedDay = { id: option.id, name: option.name };
      this.allTimes = option.times;
      this.selectedUserId = 0;
      this.showContacts = [];
    },
    setTime(option) {
      this.selectedUserId = 0;
      this.selectedTime = { id: option.id, name: option.name };
      this.showContacts = this.companyContacts.filter(function(oneContact) {
        if (option.contacts.includes(oneContact.id)) {
          return oneContact;
        }
      });
    },
    closeModal() {
      this.selectedTime = { id: null, name: "" };
      this.selectedDay = { id: null, name: "" };
      this.allTimes = [];
      this.selectedUserId = 0;
      this.selectedChatTypeId = 0;
      this.showContacts = [];
      this.$emit("close");
    },
    changeSelectedUser(number) {
      this.selectedUserId = number;
    },
    changeSelectedChatType(number) {
      this.selectedChatTypeId = number;
    },
  },
  created() {},
  computed: {
    ...mapGetters([
      "companyAppointments",
      "companyChatTypes",
      "companyContacts",
    ]),
  },
  mounted() {},
  directives: {
    ClickOutside,
  },
};
</script>
