<template>
    <div class=" w-100 mb-30">
        <div class=" row__center w-100 ">
            <img
                class="max-w-100 mb-50"
                src="../../assets/images/appointmentConfirmation.png"
            />
        </div>
        <div class="row__spacebetween w-100">
            <img
                class="max-h-290 max-w-20 mr-10"
                src="../../assets/images/appointmentConfirmationLeft.png"
            />
            <div class="simple-box w-60">
                <div class="header-small">Gesprächsart</div>
                <div class="description-big">{{ chatType.name }}</div>

                <div class="divider mt-15 mb-15"></div>
                <div class="header-small">Ausbildungsberuf</div>
                <div class="description-big">
                    {{ apprenticeship.name }}
                </div>

                <div class="divider mt-15 mb-15"></div>
                <div class="row__start">
                    <div>
                        <div class="header-small">Tag</div>
                        <div class="description-big">
                            {{ convertedDate }}
                        </div>
                    </div>
                    <div class="ml-10">
                        <div class="header-small">Zeit</div>
                        <div class="description-big">
                            {{ time }}
                        </div>
                    </div>
                </div>
                <div class="divider mt-15 mb-15"></div>
                <!-- <div class="row__start">
                    <check-box
                        :active="done"
                        :class="'border-none'"
                        @click="$emit('change', !done)"
                    />
                    <div class="header-small mt-6">
                        Hiermit bestätige ich, dass ich…
                    </div>
                </div> -->
            </div>
            <img
                class="max-h-290 max-w-20 ml-10"
                src="../../assets/images/appointmentConfirmationRight.png"
            />
        </div>
    </div>
</template>

<script>
export default {

    name: "AppointmentConfirmation",
    props: ["time", "date", "apprenticeship", "chatType", "done"],
    data() {
        return {};
    },
    methods: {},
    emits: ["change"],
    created() {},
    mounted(){
        this.$emit('change',true)
    },
    computed: {
        convertedDate(){
            var newdate = new Date(this.date);
            var day =  newdate.getDate();
            var month = (newdate.getMonth() + 1);
            var year = newdate.getFullYear();
            if(day < 10)
            {
                day = "0"+day;
            }
            if(month<10)
            {
                month = "0"+month
            }
            var convertedDate = day +"."+ month +"."+year;
            return convertedDate;

        }
    },
};
</script>
<style lang="scss" scoped>
.w-60 {
    width: calc(60% - 100px);
}
@media only screen and (max-width: 570px) {
    .w-60 {
        width: calc(100% - 80px);
    }
    .max-h-290 {
        display: none;
    }
}
</style>
