var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row__start row__start--wrap"},_vm._l((_vm.filter),function(option,key){return _c('div',{key:key},[(key == 'selectedDistricts')?_c('div',{staticClass:"row__start row__start--wrap"},_vm._l((option),function(district,index){return _c('div',{key:index,staticClass:"remove__filter--box",on:{"click":function () {
                        _vm.filter.selectedDistricts = _vm.filter.selectedDistricts.filter(
                            function (d) { return d != district; }
                        );
                    }}},[_c('p',[_vm._v(" "+_vm._s(district)+" ")]),_c('div',{staticClass:"remove__filter--svg"},[_c('CancelSvg')],1)])}),0):(
                (typeof option == 'object' && option && option.name) ||
                    (typeof option != 'object' && option)
            )?_c('div',{staticClass:"remove__filter--box",on:{"click":function () {
                    if (typeof option == 'object') {
                        _vm.filter[key] = {};
                    } else {
                        _vm.filter[key] = null;
                    }
                }}},[_c('p',[_vm._v(" "+_vm._s(typeof option == "object" && option ? option.name.trim() : option)+" ")]),_c('div',{staticClass:"remove__filter--svg"},[_c('CancelSvg')],1)]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }