var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.oneCompany && _vm.selectedCompanyImage)?_c('div',{staticClass:"h100 w-100"},[(
			_vm.oneCompany.images.length == 1 &&
				((_vm.selectedCompanyImage.fileType == 'video' &&
					_vm.youtube_parser(_vm.selectedCompanyImage.link)) ||
					_vm.selectedCompanyImage.fileType != 'video' ||
					(_vm.selectedCompanyImage.fileType == 'video' &&
						_vm.is_vimeo(_vm.selectedCompanyImage.link)))
		)?_c('div',{staticClass:"h100"},[_c('div',{staticClass:"image__box image__box--l"},[(
					_vm.selectedCompanyImage.fileType == 'video' &&
						!_vm.is_vimeo(_vm.selectedCompanyImage.link) &&
						_vm.youtube_parser(_vm.selectedCompanyImage.link)
				)?_c('youtube',{attrs:{"video-id":_vm.youtube_parser(_vm.selectedCompanyImage.link)}}):_vm._e(),(
					_vm.selectedCompanyImage.fileType == 'video' &&
						_vm.is_vimeo(_vm.selectedCompanyImage.link)
				)?_c('vimeo-player',{attrs:{"video-id":_vm.get_vimeo_id(_vm.selectedCompanyImage.link)}}):_vm._e(),(_vm.selectedCompanyImage.fileType != 'video')?_c('img',{attrs:{"src":_vm.selectedCompanyImage.link}}):_vm._e()],1)]):_vm._e(),(_vm.oneCompany.images.length > 1)?_c('div',{staticClass:"row__start row__start--stretch h100 image__scrollBox",staticStyle:{"max-width":"700px"}},[_c('div',{staticClass:"row__start--flex1 column__start scrollbar",class:_vm.$route.query.app == 1 ? 'w-25' : 'max-w-25'},_vm._l((_vm.oneCompany.images),function(image){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
					(image.fileType == 'video' &&
						_vm.youtube_parser(image.link)) ||
						image.fileType != 'video' ||
						(image.fileType == 'video' && _vm.is_vimeo(image.link))
				),expression:"\n\t\t\t\t\t(image.fileType == 'video' &&\n\t\t\t\t\t\tyoutube_parser(image.link)) ||\n\t\t\t\t\t\timage.fileType != 'video' ||\n\t\t\t\t\t\t(image.fileType == 'video' && is_vimeo(image.link))\n\t\t\t\t"}],key:image.id,staticClass:"image__container pointer",class:image.id == _vm.selectedCompanyImage.id
						? 'image__container--active'
						: '',on:{"click":function($event){$event.stopPropagation();return _vm.changeCompanyImage(image)}}},[(
						image.fileType == 'video' &&
							_vm.youtube_parser(image.link) &&
							!_vm.is_vimeo(image.link)
					)?_c('youtube',{attrs:{"video-id":_vm.youtube_parser(image.link)}}):_vm._e(),(image.fileType == 'video' && _vm.is_vimeo(image.link))?_c('vimeo-player',{staticStyle:{"height":"10vh"},attrs:{"video-id":_vm.get_vimeo_id(image.link)}}):_vm._e(),(image.fileType == 'video')?_c('div',{staticClass:"absolute--overlay",on:{"click":function($event){$event.stopPropagation();return _vm.changeCompanyImage(image)}}}):_vm._e(),(image.fileType != 'video')?_c('img',{attrs:{"src":image.link}}):_vm._e()],1)}),0),_c('div',{staticClass:"row__start--flex3"},[_c('div',{staticClass:"image__box image__box--l",class:_vm.selectedCompanyImage.fileType == 'video' &&
					!_vm.is_vimeo(_vm.selectedCompanyImage.link)
						? ''
						: 'image__box--hide'},[(
						_vm.selectedCompanyImage.fileType == 'video' &&
							!_vm.is_vimeo(_vm.selectedCompanyImage.link)
					)?_c('LazyYoutube',{ref:"lazyVideo",attrs:{"src":_vm.selectedCompanyImage.link,"max-width":"95%","aspect-ratio":"16:9","thumbnail-quality":"standard"}}):_vm._e()],1),_c('div',{staticClass:"image__box image__box--l",class:_vm.selectedCompanyImage.fileType == 'video' &&
					_vm.is_vimeo(_vm.selectedCompanyImage.link)
						? ''
						: 'image__box--hide'},[(
						_vm.selectedCompanyImage.fileType == 'video' &&
							_vm.is_vimeo(_vm.selectedCompanyImage.link)
					)?_c('LazyVimeo',{ref:"vimeoLazyVideo",attrs:{"src":_vm.selectedCompanyImage.link,"max-width":"95%","aspect-ratio":"16:9","thumbnail-quality":"standard"}}):_vm._e()],1),_c('div',{staticClass:"image__box image__box--l",class:_vm.selectedCompanyImage.fileType != 'video'
						? ''
						: 'image__box--hide'},[_c('a',{on:{"click":function($event){_vm.showLightbox = true}}},[(_vm.selectedCompanyImage.fileType != 'video')?_c('img',{attrs:{"src":_vm.selectedCompanyImage.link}}):_vm._e()])]),_c('div',[(_vm.showLightbox && _vm.$route.query.app != 1)?_c('div',{staticClass:"lightbox",attrs:{"id":"images"},on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }_vm.showLightbox = false}}},[_c('span',{staticStyle:{"position":"absolute","top":"0.7em","right":"1.2em","cursor":"pointer"},on:{"click":function($event){_vm.showLightbox = false}}},[_c('CancelSvg')],1),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.previousImage()}}},[_c('ArrowLeftDefaultSvg')],1),(_vm.selectedCompanyImage.fileType == 'image')?_c('div',[_c('span',[_c('img',{attrs:{"src":_vm.selectedCompanyImage.link}})])]):_vm._e(),(
							_vm.selectedCompanyImage.fileType == 'video' &&
								_vm.is_vimeo(_vm.selectedCompanyImage.link)
						)?_c('div',[(
								_vm.selectedCompanyImage.fileType == 'video' &&
									_vm.is_vimeo(_vm.selectedCompanyImage.link)
							)?_c('LazyVimeo',{ref:"vimeoLazyVideo",attrs:{"src":_vm.selectedCompanyImage.link,"max-width":"95%","aspect-ratio":"16:9","thumbnail-quality":"standard"}}):_vm._e()],1):_vm._e(),(
							_vm.selectedCompanyImage.fileType == 'video' &&
								!_vm.is_vimeo(_vm.selectedCompanyImage.link)
						)?_c('div',[(
								_vm.selectedCompanyImage.fileType == 'video' &&
									!_vm.is_vimeo(_vm.selectedCompanyImage.link)
							)?_c('LazyYoutube',{ref:"youtubeLazyVideo",attrs:{"src":_vm.selectedCompanyImage.link,"max-width":"95%","aspect-ratio":"16:9","thumbnail-quality":"standard"}}):_vm._e()],1):_vm._e(),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.nextImage()}}},[_c('ArrowRightDefaultSvg')],1)]):_vm._e()])])]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }