import Axios from "axios";
import router from "../../router";

const state = {
	myAppointments: [],
	/**
	 * !old Function
	 */
	companyAppointments: [],
};

const getters = {
	myAppointments: (state) => state.myAppointments,
	companyAppointments: (state) => state.companyAppointments,
};
const mutations = {
	setMyAppointments: (state, bool) => {
		state.myAppointments = bool;
	},
	/**
	 * !old Function
	 */
	setCompanyAppointments: (state, value) => {
		state.companyAppointments = value;
	},
	setAppointmentContacts: (state, value) => {
		state.appointmentContacts = value;
	},
};
const actions = {
	async bookAppointment(
		{ commit, dispatch },
		{
			chatTypeId,
			day,
			time,
			contactId,
			companyId,
			phoneNumber,
			firstName,
			lastName,
			apprenticeshipId,
		}
	) {
		return await Axios.post(window.baselink + "bookAppointment", {
			chatTypeId,
			day,
			time,
			contactId,
			companyId,
			phoneNumber,
			firstName,
			lastName,
			apprenticeshipId,
		})
			.then(function(response) {
				if (response.status == 201) {
					/**
					 * !old Function
					 */
					// commit("setCompanyAppointments", response.data.company.workshops);
					// commit("setCompanyChatTypes", response.data.company.chat_types);
					// commit("setCompanyContacts", response.data.company.contacts);

					/**
					 * !new Function
					 *
					 * @var {[type]}
					 */
					commit("setCompanyChatTypes", response.data.chatTypes);
					commit(
						"setCompanyAppointments",
						response.data.appointments
					);
				}
				return response.status;
			})
			.catch(function(error) {
				if (error.response.status == 401) {
					window.localStorage.removeItem("token");
					dispatch("setToken", null);
					router.push({
						name: "Login",
					});
				}
				return error.response.status;
			});
	},
	async getCompanyChatTypes({ commit }, { id }) {
		await Axios.get(window.baselink + "getCompanyChatTypes/" + id)
			.then(function(response) {
				if (response.status == 200) {
					commit("setCompanyChatTypes", response.data.chatTypes);
				}
			})
			.catch(function(error) {
				if (error.response.status == 401) {
					window.localStorage.removeItem("token");
					// dispatch("setToken", null);
					// router.push({
					//     name: "Login",
					// });
				}
			});
	},
	async openAppointmentBooking(
		{ commit },
		{ company_id, apprenticeship_id }
	) {
		await Axios.get(
			window.baselink +
				"getCompanyAppointments/" +
				company_id +
				"/apprenticeship/" +
				apprenticeship_id
		)
			.then(function(response) {
				// console.log(response.data)
				if (response.status == 200) {
					/**
					 * !old Function
					 */
					// commit("setCompanyAppointments", response.data.company.workshops);
					// commit("setCompanyChatTypes", response.data.company.chat_types);
					// commit("setCompanyContacts", response.data.company.contacts);

					/**
					 * !new Function
					 *
					 * @var {[type]}
					 */
					commit("setCompanyChatTypes", response.data.chatTypes);
					commit(
						"setCompanyAppointments",
						response.data.appointments
					);
				}
			})
			.catch(function(error) {
				if (error.response.status == 401) {
					window.localStorage.removeItem("token");
					// dispatch("setToken", null);
					// router.push({
					//     name: "Login",
					// });
				}
			});
	},
	async getMyAppointments({ commit, dispatch }) {
		await Axios.get(window.baselink + "getMyAppointments")
			.then(function(response) {
				console.log(response);
				if (response.status == 200) {
					commit("setMyAppointments", response.data.appointments);
				}
			})
			.catch(function(error) {
				if (error.response.status == 401) {
					window.localStorage.removeItem("token");
					dispatch("setToken", null);
					router.push({
						name: "Login",
					});
				}
			});
	},
	async deleteAppointment({ dispatch }, { appointmentId }) {
		return await Axios.post(window.baselink + "deleteAppointment", {
			appointmentId,
		})
			.then(function(response) {
				if (response.status == 200) {
					return true;
				}
			})
			.catch(function(error) {
				if (error.response.status == 401) {
					window.localStorage.removeItem("token");
					dispatch("setToken", null);
					router.push({
						name: "Login",
					});
				}
				return false;
			});
	},
};

export default {
	namespace: "appointment",
	state,
	getters,
	actions,
	mutations,
};
