var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"searchbox row__spacebetween"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.filter.companyName),expression:"filter.companyName"}],attrs:{"type":"text","placeholder":"Freitextsuche"},domProps:{"value":(_vm.filter.companyName)},on:{"change":function($event){return _vm.$emit('filterChanged')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.filter, "companyName", $event.target.value)}}}),_c('SearchSvg')],1),_c('p',{staticClass:"text__primary m-15"},[_vm._v("oder")]),_c('div',{staticClass:"row__start row__start--wrap"},_vm._l((_vm.districtOptions),function(district){return _c('CheckBox',{key:district,staticClass:"w-50-5 mr-5 mb-10",attrs:{"text":district.trim(),"active":_vm.filter.selectedDistricts.includes(district)},on:{"click":function () {
                    if (_vm.filter.selectedDistricts.includes(district)) {
                        _vm.filter.selectedDistricts = _vm.filter.selectedDistricts.filter(
                            function (d) { return d != district; }
                        );
                    } else {
                        _vm.filter.selectedDistricts.push(district);
                    }
                }}})}),1),(_vm.$route.query.app == 1)?_c('p',{staticClass:"text__primary company_counter mt-15 mb-20"},[_vm._v(" "+_vm._s(_vm.count)+" Ergebnisse für deine Suche ")]):_c('p',{staticClass:"text__primary company_counter mb-50"}),_c('general-select',{attrs:{"classes":"selectbox--big","placeholder":"Ort","noName":true,"options":_vm.filteredPlaceOptions,"selectedItem":_vm.filter.selectedPlace},on:{"change":function (option) {
                if (_vm.filter.selectedPlace == option || option == 'Alle') {
                    _vm.filter.selectedPlace = null;
                } else {
                    _vm.filter.selectedPlace = option;
                }
            }}}),_c('general-select',{staticClass:"mt-15",attrs:{"classes":"selectbox--big","placeholder":"Ausbildungsberuf","options":_vm.apprenticeshipOptions,"selectedItem":_vm.filter.selectedApprenticeship,"readonly":false},on:{"change":function (option) {
                if (
                    _vm.filter.selectedApprenticeship.id == option.id ||
                    option.id == null
                ) {
                    _vm.filter.selectedApprenticeship = {};
                } else {
                    _vm.filter.selectedApprenticeship = option;
                }
            }}}),_c('general-select',{staticClass:"mt-15",attrs:{"classes":"selectbox--big","placeholder":"Unternehmen","options":_vm.companyOptions,"selectedItem":_vm.filter.selectedCompany},on:{"change":function (option) {
                if (
                    _vm.filter.selectedCompany.id == option.id ||
                    option.id == null
                ) {
                    _vm.filter.selectedCompany = {};
                } else {
                    _vm.filter.selectedCompany = option;
                }
            }}}),_c('general-select',{staticClass:"mt-15",attrs:{"classes":"selectbox--big","placeholder":"Berufsfelder","options":_vm.occupationOptions,"selectedItem":_vm.filter.selectedOccupation},on:{"change":function (option) {
                if (
                    _vm.filter.selectedOccupation.id == option.id ||
                    option.id == null
                ) {
                    _vm.filter.selectedOccupation = {};
                } else {
                    _vm.filter.selectedOccupation = option;
                }
            }}}),_c('general-select',{staticClass:"mt-15",attrs:{"classes":"selectbox--big","placeholder":"Ich suche...","options":_vm.offerOptions,"selectedItem":_vm.filter.selectedOffer},on:{"change":function (option) {
                if (
                    _vm.filter.selectedOffer.id == option.id ||
                    option.id == null
                ) {
                    _vm.filter.selectedOffer = {};
                } else {
                    _vm.filter.selectedOffer = option;
                }
            }}}),_c('div',{staticClass:"row__spacebetween"},_vm._l((_vm.years),function(year){return _c('CheckBox',{key:year,staticClass:"w-50-5 mt-15  mb-10",attrs:{"text":year,"active":year == _vm.filter.selectedYear},on:{"click":function () {
                    if (_vm.filter.selectedYear == year) {
                        _vm.filter.selectedYear = null;
                    } else {
                        _vm.filter.selectedYear = year;
                    }
                }}})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }