<template>
  <div class="absoluteBox settingsBox absoluteBox--rightTop">
    <router-link class="ph-10" :to="{ name: 'Settings' }">
      <p class="ph-10 p--selectitem">Einstellungen</p>
    </router-link>
    <router-link class="ph-10" :to="{ name: 'Calendar' }">
      <p class="ph-10 p--selectitem">Termine</p>
    </router-link>
    <p class="ph-10 p--selectitem" @click="prepareLogout">Logout</p>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "NavbarUserOptions",
  data() {
    return {
      links: [{ id: 1, name: "Home", label: "Startseite" }],
    };
  },
  methods: {
    ...mapActions(["logoutUser"]),
    async prepareLogout() {
      await this.logoutUser();
      const path = `/`;
      if (this.$route.path !== path) {
        this.$router.push({
          name: "Home",
        });
      }
    },
  },
  created() {},
  computed: {
    ...mapGetters([]),
  },
  mounted() {},
};
</script>
