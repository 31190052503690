import Axios from "axios";

const state = {
    offerOptions: [],
    occupationOptions: [],
    districtOptions: [],
    placeOptions: [],
    companyOptions: [],
    apprenticeshipOptions: [],
};

const getters = {
    offerOptions: (state) => state.offerOptions,
    occupationOptions: (state) => state.occupationOptions,
    districtOptions: (state) => state.districtOptions,
    placeOptions: (state) => state.placeOptions,
    companyOptions: (state) => state.companyOptions,
    apprenticeshipOptions: (state) => state.apprenticeshipOptions,
};
const mutations = {
    setOfferOptions: (state, array) => {
        state.offerOptions = array;
    },
    setOccupationOptions: (state, array) => {
        state.occupationOptions = array;
    },

    setDistrictOptions: (state, array) => {
        state.districtOptions = array;
    },
    setPlaceOptions: (state, array) => {
        state.placeOptions = array;
    },
    setCompanyOptions: (state, array) => {
        state.companyOptions = array;
    },
    setApprenticeshipOptions: (state, array) => {
        state.apprenticeshipOptions = array;
    },
};
const actions = {
    async getDashboardOptions({ commit }) {
        await Axios.get(window.baselink + "getDashboardOptions").then(function(
            response
        ) {
            if (response.status == 200) {
                commit("setPlaceOptions", response.data.cities);
                commit("setCompanyOptions", response.data.companies);
                commit("setDistrictOptions", response.data.districts);
                commit("setOccupationOptions", response.data.occupations);
                commit("setOfferOptions", response.data.offers);
                commit(
                    "setApprenticeshipOptions",
                    response.data.apprenticeships
                );
            }
        });
    },
};

export default {
    namespace: "options",
    state,
    getters,
    actions,
    mutations,
};
