var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"max-w-700",class:_vm.$route.query.app != 1 ? 'max-h-55' : ''},[_c('img',{staticClass:"max-w-100 mb-50",attrs:{"src":require("../../assets/images/appointmentOffer.png"),"alt":""}}),_c('div',{staticClass:"row__spacebetween row__spacebetween--wrap w-100",style:(_vm.$route.query.app == 1 ? 'max-width:95vw;' : '')},_vm._l((_vm.oneCompany.apprenticeships),function(apprenticeship,index){return _c('div',{key:index,staticClass:"company__result--box",class:apprenticeship.id == _vm.selectedApprenticeship.id
						? 'active'
						: '',on:{"click":function($event){$event.stopPropagation();return _vm.$emit('change', {
						id: apprenticeship.id,
						name: apprenticeship.name,
						contact: apprenticeship.company_contact.name,
						contactId: apprenticeship.company_contact.id,
						companyId: _vm.oneCompany.id,
					})}}},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"company__box--text company__box--header "},[_vm._v(" "+_vm._s(apprenticeship.name)+" ")]),_c('div',{staticClass:" mt-10 row__start flex-wrap"},[(apprenticeship.city)?_c('div',{staticClass:"company__box--information company__box--text mb-5 mr-10"},[_vm._v(" "+_vm._s(apprenticeship.city)+" ")]):_vm._e(),_vm._l((apprenticeship.occupations),function(occupation,secondIndex){return _c('div',{key:secondIndex,staticClass:"company__box--information company__box--text mb-5 mr-10"},[_vm._v(" "+_vm._s(occupation.name)+" ")])}),_c('div',{staticClass:"company__box--information company__box--text mb-5 mr-10"},[_vm._v(" "+_vm._s(apprenticeship.offer.name)+" ")]),(apprenticeship.year)?_c('div',{staticClass:"company__box--information company__box--text mb-5 mr-10"},[_vm._v(" "+_vm._s(apprenticeship.year)+" ")]):_vm._e()],2)])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }