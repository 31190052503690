<template>
    <div class=" w-100 mb-30">
        <div class=" row_end w-100 ">
            <img
                class="max-w-100 mb-50 ml-auto"
                src="../../assets/images/appointmentTime.png"
            />
        </div>
        <div class="simple-box">
            <div class="row__center">
                <div class="header">Uhrzeit</div>
            </div>
            <div class="divider mt-15 mb-20"></div>
            <div class="row__start row__start--wrap" style="max-height:200px; overflow-y:scroll;">
                <div
                    class="timebox"
                    :class="{ active: selectedTime == time.name }"
                    @click="$emit('change', time.name)"
                    v-for="(time, index) in times"
                    :key="index"
                >
                    {{ time.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {},
    name: "AppointmentTime",
    data() {
        return {};
    },
    props: ["selectedTime","selectedWorkshop"],
    emits: ["change"],
    methods: {},
    created() {},
    computed: {
        ...mapGetters(["companyAppointments"]),
        times() {
            return this.companyAppointments[this.selectedWorkshop];
        },
    },
};
</script>
