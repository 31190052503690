<template>
	<div>
		<div class="max-w-700" :class="$route.query.app != 1 ? 'max-h-55' : ''">
			<img
				class="max-w-100 mb-50"
				src="../../assets/images/appointmentOffer.png"
				alt=""
			/>

			<div
				class="row__spacebetween row__spacebetween--wrap w-100"
				:style="$route.query.app == 1 ? 'max-width:95vw;' : ''"
			>
				<div
					v-for="(apprenticeship,
					index) in oneCompany.apprenticeships"
					:key="index"
					class="company__result--box"
					:class="
						apprenticeship.id == selectedApprenticeship.id
							? 'active'
							: ''
					"
					@click.stop="
						$emit('change', {
							id: apprenticeship.id,
							name: apprenticeship.name,
							contact: apprenticeship.company_contact.name,
							contactId: apprenticeship.company_contact.id,
							companyId: oneCompany.id,
						})
					"
				>
					<div class="w-100">
						<div class="company__box--text company__box--header ">
							{{ apprenticeship.name }}
						</div>
						<div class=" mt-10 row__start flex-wrap">
							<div
								class="company__box--information company__box--text mb-5 mr-10"
								v-if="apprenticeship.city"
							>
								{{ apprenticeship.city }}
							</div>
							<div
								class="company__box--information company__box--text mb-5 mr-10"
								v-for="(occupation,
								secondIndex) in apprenticeship.occupations"
								:key="secondIndex"
							>
								{{ occupation.name }}
							</div>
							<div
								class="company__box--information company__box--text mb-5 mr-10"
							>
								{{ apprenticeship.offer.name }}
							</div>
							<div
								class="company__box--information company__box--text mb-5 mr-10"
								v-if="apprenticeship.year"
							>
								{{ apprenticeship.year }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from "vuex";
	export default {
		components: {},
		name: "AppointmentApprenticeship",

		props: ["selectedApprenticeship"],
		methods: {},
		emits: ["change"],
		created() {},
		computed: {
			...mapGetters(["oneCompany"]),
		},
	};
</script>
<style lang="scss" scoped>
	@media only screen and (min-width: 1020px) {
		.company__result--box {
			width: calc(50% - 40px) !important;
			max-width: calc(50% - 40px) !important;
		}
	}
	@media only screen and (max-width: 900px) {
		.company__result--box {
			min-width: calc(100% - 40px) !important;
			width: calc(100% - 40px) !important;
			max-width: calc(100% - 40px) !important;
		}
	}
	.row__spacebetween {
		max-width: 100vw;
	}
</style>
