<template>
    <div class="row__center">
        <div class="max-w-700">
            <img
                class="max-w-100 mb-50"
                src="../../assets/images/appointmentType.png"
                alt=""
                style="width:100vw;"
            />
            <div class="row__spacebetween mb-50">
                <CheckBox
                    v-for="chatType in companyChatTypes"
                    :key="chatType.id"
                    class="w-25"
                    :active="chatType.id == selectedChatType.id ? true : false"
                    :text="chatType.name"
                    :textBig="true"
                    @click="$emit('change', chatType)"
                />
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    components: {},
    name: "AppointmentType",

    props: ["selectedChatType"],
    methods: {},
    emits: ["change"],
    created() {},
    computed: { ...mapGetters(["companyChatTypes"]) },
};
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 470px) {
    .row__spacebetween {
        flex-wrap: wrap;
    }
    .w-25 {
        width: 100%;
        margin-bottom: 10px;
    }
}
</style>
