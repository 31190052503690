<template>
    <div class="footerLogoContainer" style="margin-bottom:10px;">
        <img :src="image" alt="" />
    </div>
</template>
<script>
export default {
    props: ["logo"],
    data() {
        return {};
    },
    computed: {
        image() {
            return require("../../public/img/images/companies/" +
                this.logo +
                ".png");
        },
    },
};
</script>
<style scoped>
.footerLogoContainer {
    width: 200px;
    height: 100px;
    background: white;
    margin-right: 25px;
    justify-content: center;
    display: flex;
}
img {
    object-fit: scale-down;
    padding: 15px;
}
</style>
